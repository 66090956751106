import { Typography } from '@mui/material';

function BonusQuestions() {
  return (
    <Typography variant={'h4'}>
      Bonusvragen
    </Typography>
  );
}

export default BonusQuestions;
