import React from 'react';
import Game from './Game';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import { Container, createTheme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import PoulesView from './PoulesView';

function App () {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#d2196c'
      },
      secondary: {
        main: '#dc004e'
      }
    }
  });
  return (
    <React.Fragment>
      <CssBaseline/>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Container fixed><Home/></Container>}/>
            <Route path="/game" element={<Container><Game/></Container>}/>
            <Route path="/poules" element={<Container><PoulesView/></Container>}/>
          </Routes>
        </Router>
      </ThemeProvider>

    </React.Fragment>

  );
}

export default App;
