import React from 'react';
import { Grid2 as Grid, Paper, Typography, TextField } from '@mui/material';

function MatchPrediction({ game, scores, handleScoreChange }) {
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <Typography variant="h6">
            {game.homeTeam} vs {game.awayTeam}
          </Typography>
          <Typography variant="body2">
            {game.date} om {game.time} - {game.city}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label={`${game.homeTeam} Score`}
            type="number"
            size="small"
            value={scores[game.id].homeScore}
            onChange={(e) => handleScoreChange(game.id, 'homeScore', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label={`${game.awayTeam} Score`}
            type="number"
            size="small"
            value={scores[game.id].awayScore}
            onChange={(e) => handleScoreChange(game.id, 'awayScore', e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default MatchPrediction;
