import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid2 as Grid, TextField } from '@mui/material';

function Home () {
  const navigate = useNavigate();
  return (

    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid>
          <h1>Vrienden van de bal voetbalpoule</h1>
        </Grid>
        <Grid item size={12}>
          <Grid>
            <TextField size="small" id="outlined-basic" label="Wie ben je" variant="outlined"/>
          </Grid>
        </Grid>
        <Grid item size={12}>
          <Button onClick={() => navigate('/game')} variant="contained">Start</Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
