import React, { useState } from 'react';
import { Box, Button, Grid, Grid2, Paper, Typography } from '@mui/material';
import MatchPrediction from './MatchPrediction';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BonusQuestions from './BonusQuestions';
import PoulesView from './PoulesView';
import ScienceIcon from '@mui/icons-material/Science';

// Gegevens van de wedstrijden voor het EK 2024 (speeldatum en speelstad toegevoegd)
const gamesData = [
  // Group A
  { id: 1, homeTeam: 'Duitsland', awayTeam: 'Schotland', date: '14 juni 2024', city: 'München' },
  { id: 2, homeTeam: 'Hongarije', awayTeam: 'Zwitserland', date: '15 juni 2024', city: 'Keulen' },
  { id: 3, homeTeam: 'Duitsland', awayTeam: 'Hongarije', date: '19 juni 2024', city: 'Stuttgart' },
  { id: 4, homeTeam: 'Schotland', awayTeam: 'Zwitserland', date: '19 juni 2024', city: 'Frankfurt' },
  { id: 5, homeTeam: 'Duitsland', awayTeam: 'Zwitserland', date: '23 juni 2024', city: 'Keulen' },
  { id: 6, homeTeam: 'Schotland', awayTeam: 'Hongarije', date: '23 juni 2024', city: 'München' },

  // Group B
  { id: 7, homeTeam: 'Spanje', awayTeam: 'Kroatië', date: '15 juni 2024', city: 'Stuttgart' },
  { id: 8, homeTeam: 'Italië', awayTeam: 'Albanië', date: '16 juni 2024', city: 'Dortmund' },
  { id: 9, homeTeam: 'Spanje', awayTeam: 'Italië', date: '20 juni 2024', city: 'Gelsenkirchen' },
  { id: 10, homeTeam: 'Kroatië', awayTeam: 'Albanië', date: '20 juni 2024', city: 'Düsseldorf' },
  { id: 11, homeTeam: 'Spanje', awayTeam: 'Albanië', date: '24 juni 2024', city: 'Dortmund' },
  { id: 12, homeTeam: 'Kroatië', awayTeam: 'Italië', date: '24 juni 2024', city: 'Stuttgart' },

  // Group C
  { id: 13, homeTeam: 'Nederland', awayTeam: 'Oostenrijk', date: '16 juni 2024', city: 'Hamburg' },
  { id: 14, homeTeam: 'Frankrijk', awayTeam: 'Roemenië', date: '17 juni 2024', city: 'Leipzig' },
  { id: 15, homeTeam: 'Nederland', awayTeam: 'Frankrijk', date: '21 juni 2024', city: 'Berlijn' },
  { id: 16, homeTeam: 'Oostenrijk', awayTeam: 'Roemenië', date: '21 juni 2024', city: 'Frankfurt' },
  { id: 17, homeTeam: 'Nederland', awayTeam: 'Roemenië', date: '25 juni 2024', city: 'Hamburg' },
  { id: 18, homeTeam: 'Oostenrijk', awayTeam: 'Frankrijk', date: '25 juni 2024', city: 'Leipzig' },

  // Group D
  { id: 19, homeTeam: 'Turkije', awayTeam: 'Wales', date: '17 juni 2024', city: 'Frankfurt' },
  { id: 20, homeTeam: 'België', awayTeam: 'Denemarken', date: '18 juni 2024', city: 'Gelsenkirchen' },
  { id: 21, homeTeam: 'Turkije', awayTeam: 'België', date: '22 juni 2024', city: 'Berlijn' },
  { id: 22, homeTeam: 'Wales', awayTeam: 'Denemarken', date: '22 juni 2024', city: 'Hamburg' },
  { id: 23, homeTeam: 'Turkije', awayTeam: 'Denemarken', date: '26 juni 2024', city: 'Gelsenkirchen' },
  { id: 24, homeTeam: 'Wales', awayTeam: 'België', date: '26 juni 2024', city: 'Frankfurt' },

  // Group E
  { id: 25, homeTeam: 'Polen', awayTeam: 'Finland', date: '18 juni 2024', city: 'Berlijn' },
  { id: 26, homeTeam: 'Portugal', awayTeam: 'Tsjechië', date: '19 juni 2024', city: 'Düsseldorf' },
  { id: 27, homeTeam: 'Polen', awayTeam: 'Portugal', date: '23 juni 2024', city: 'Hannover' },
  { id: 28, homeTeam: 'Finland', awayTeam: 'Tsjechië', date: '23 juni 2024', city: 'München' },
  { id: 29, homeTeam: 'Polen', awayTeam: 'Tsjechië', date: '27 juni 2024', city: 'Berlijn' },
  { id: 30, homeTeam: 'Finland', awayTeam: 'Portugal', date: '27 juni 2024', city: 'Düsseldorf' },

  // Group F
  { id: 31, homeTeam: 'Slovenië', awayTeam: 'Slowakije', date: '19 juni 2024', city: 'Hannover' },
  { id: 32, homeTeam: 'Servië', awayTeam: 'Engeland', date: '20 juni 2024', city: 'München' },
  { id: 33, homeTeam: 'Slovenië', awayTeam: 'Servië', date: '24 juni 2024', city: 'Leipzig' },
  { id: 34, homeTeam: 'Slowakije', awayTeam: 'Engeland', date: '24 juni 2024', city: 'Stuttgart' },
  { id: 35, homeTeam: 'Slovenië', awayTeam: 'Engeland', date: '28 juni 2024', city: 'München' },
  { id: 36, homeTeam: 'Slowakije', awayTeam: 'Servië', date: '28 juni 2024', city: 'Hannover' }
];


function getPanelProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function Game() {
  const [scores, setScores] = useState(
    gamesData.reduce((acc, game) => {
      acc[game.id] = { homeScore: '', awayScore: '' };
      return acc;
    }, {})
  );

  const handleScoreChange = (gameId, team, value) => {
    setScores((prevScores) => ({
      ...prevScores,
      [gameId]: { ...prevScores[gameId], [team]: value },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted Scores:', scores);
    alert('Scores submitted! Check console for details.');
  };

  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 3 }}>
      <Tabs value={tabValue} aria-label="basic tabs example" onChange={handleChange}>
        <Tab label="Voorspel uitslagen" {...getPanelProps(0)} />
        <Tab label="Poules" {...getPanelProps(1)} />
        <Tab label="Bonusvragen" {...getPanelProps(2)} />
      </Tabs>


      <CustomTabPanel value={tabValue} index={0}>
        <Typography variant={'h4'} sx={{ marginTop: 2 }}>
          Voorspel de uitslagen
        </Typography>
        {gamesData.map((game) => (
          <MatchPrediction key={game.id} game={game} scores={scores} handleScoreChange={handleScoreChange} />
        ))}
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: 2 }}>
          <Button variant="contained" type="submit" onClick={handleSubmit}>Verstuur voorspelling</Button>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <Typography variant={'h4'} sx={{ marginTop: 2 }}>
          Voorspel de poules
        </Typography>
        <PoulesView />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <Typography variant={'h4'} sx={{ marginTop: 2 }}>
          Beantwoord de bonusvragen
        </Typography>
        <BonusQuestions />
      </CustomTabPanel>
    </Paper>
  );
}


export default Game;
