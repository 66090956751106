import React, { useState } from 'react';
import {
  Box,
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Alert,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './fixes/StrictModeDroppable.tsx';

// Euro 2024 Teams with Dutch names and flags grouped by their respective poules
const poulesData = [
  { id: 'A', teams: ['🇩🇪 Duitsland', '🇸🇨 Schotland', '🇭🇺 Hongarije', '🇨🇭 Zwitserland'] },
  { id: 'B', teams: ['🇪🇸 Spanje', '🇭🇷 Kroatië', '🇮🇹 Italië', '🇦🇱 Albanië'] },
  { id: 'C', teams: ['🇳🇱 Nederland', '🇦🇹 Oostenrijk', '🇫🇷 Frankrijk', '🇷🇴 Roemenië'] },
  { id: 'D', teams: ['🇹🇷 Turkije', '🏴 Wales', '🇧🇪 België', '🇩🇰 Denemarken'] },
  { id: 'E', teams: ['🇵🇱 Polen', '🇫🇮 Finland', '🇵🇹 Portugal', '🇨🇿 Tsjechië'] },
  { id: 'F', teams: ['🇸🇮 Slovenië', '🇸🇰 Slowakije', '🇷🇸 Servië', '🏴 Engeland'] },
];

function PouleTable({ pouleId, teams, onDragEnd, isChanged }) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId={`poule-${pouleId}`}>
        {(provided) => (
          <TableContainer
            component={Paper}
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              mb: 4, // Add some margin between tables
              border: isChanged ? 'none' : '2px solid red', // Red border if not changed
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Positie</TableCell>
                  <TableCell>Team</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teams.map((team, index) => (
                  <Draggable key={team} draggableId={`${pouleId}-${team}`} index={index}>
                    {(provided) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{team}</TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
}

function PoulesView() {
  const [poules, setPoules] = useState(poulesData);
  const [changedPoules, setChangedPoules] = useState(
    poulesData.reduce((acc, poule) => ({ ...acc, [poule.id]: false }), {})
  );
  const [errorMessage, setErrorMessage] = useState('');

  const handleDragEnd = (result) => {
    if (!result.destination) return; // If dropped outside

    const [poulePrefix] = result.draggableId.split('-');
    const pouleIndex = poules.findIndex((poule) => poule.id === poulePrefix);

    if (pouleIndex === -1) return; // Check if the pouleIndex is valid

    const newOrder = Array.from(poules[pouleIndex].teams);
    const [movedTeam] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedTeam);

    const newPoules = [...poules];
    newPoules[pouleIndex].teams = newOrder;
    setPoules(newPoules);

    // Mark this poule as changed
    setChangedPoules((prev) => ({ ...prev, [poulePrefix]: true }));
  };

  const handleSubmit = () => {
    // Check if all poules have been changed at least once
    const allChanged = Object.values(changedPoules).every((changed) => changed);
    if (!allChanged) {
      setErrorMessage('Je moet de volgorde van alle poules veranderen voordat je doorgaat.');
      return;
    }

    setErrorMessage('');
    alert('Alle poules zijn succesvol aangepast en opgeslagen!');
    // Save or handle the results as needed
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Voorspel de volgorde van de Poules (Euro 2024)
      </Typography>

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      <Grid container spacing={4}>
        {poules.map((poule) => (
          <Grid item xs={12} md={4} key={poule.id}>
            <Typography variant="h6" gutterBottom>
              Groep {poule.id}
            </Typography>
            <PouleTable
              pouleId={poule.id}
              teams={poule.teams}
              onDragEnd={handleDragEnd}
              isChanged={changedPoules[poule.id]} // Pass whether the poule has been changed
            />
          </Grid>
        ))}
      </Grid>

      <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 3 }}>
        Bevestig Volgorde
      </Button>
    </Box>
  );
}

export default PoulesView;
